#main {
  padding-top: 20px;
}

.container {
  max-width: 600px;
  padding: 0px 20px 100px;
  min-height: 100vh;
  margin: 0 auto;
  text-align: center;
}

.profileImage {
  height: 110px;
  width: 110px;
}

.websiteName {
  padding-top: 20px;
}

.websiteName span {
  margin-left: 12px;
}

.loadingSpinner {
  top: 50%;
  left: 50%;
  position: absolute;
}

.textWithIcon {
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: bold;
}

.float{
  padding: 12px;
	position:fixed;
	width:60px;
	height:60px;
	bottom:20px;
	right:20px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  z-index:100;
}

.float:hover{
  cursor:pointer;
  	box-shadow: 2px 2px 3px #999;
}