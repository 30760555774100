/* .links {
    max-width: 500px;
} */
.rowLink a {
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.3em;
}

.rowLink button {
    background: none;
    border: none;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.3em;
}

.linkDesc {
    font-size: 0.8em;
    padding-top: 10px;
    text-transform: none;
    text-align: center;
    word-wrap: break-word;
}

.description {
    font-size: 1em;
}

.linkIcon {
    margin-right: 10px;
    font-size: 1.3em;
    vertical-align: top;
}

.linkImage {
    height: 40px;
    width: 40px;
    margin-left: 10px;
}

.accordion-content{
    max-width: 90%;
    margin-left: 5%;
}


.linkName {
    padding: 0 40px;
    margin-right: 10px;
}

@media only screen and (min-width: 500px) {
    .link.col {
        margin-left: -25px;
    }
    /* .linkDesc {
        margin-left: -60px;
    } */
}

