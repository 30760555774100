/* General Styles */
#landing-page body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#landing-page .container {
  max-width: none; /* Overwrite global max-width */
  padding: 20px; /* Adjust padding as needed */
  min-height: auto; /* Remove 100vh if not needed */
  margin: 0; /* Remove global centering */
  text-align: left; /* Allow individual text alignment */
}

#landing-page .subtitle {
  font-size: 1.75rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}

#landing-page .description {
  font-size: 1rem;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 1rem;
}

#landing-page .image-section {
  margin-top: 2rem;
}

#landing-page .image {
  width: 100%;
  max-width: 150px;
  border-radius: 10px;
  margin-bottom: 0.5rem;
}

#landing-page .image-text {
  margin-top: 0.25rem;
  font-size: 0.9rem;
  color: #666;
}


#landing-page .navbar {
  width: 100%;
  margin: 0;
  padding: 0;
}

#landing-page .logo {
  width: 300px;
  height: auto;
}

@media (max-width: 767px) {
  #landing-page .logo {
    width: 200px;
  }
}

/* Subtitle and Description */
#landing-page .subtitle {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}

#landing-page .description {
  font-size: 1.1rem;
  color: #555;
  max-width: 600px;
  margin: 0 auto;
}

/* Image Section */
#landing-page .image-section {
  margin-top: 50px;
}

#landing-page .image {
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 10px;
}

#landing-page .image-text {
  margin-top: 10px;
  font-size: 1rem;
  color: #666;
}

/* Estilo para h1 */
#landing-page h1 {
  font-size: 2.5rem; /* Tamaño grande para destacar */
  font-weight: 700; /* Negrita para énfasis */
  color: #333; /* Color oscuro para buena legibilidad */
  text-align: center; /* Centramos el texto */
  margin-bottom: 1rem; /* Espaciado inferior */
  line-height: 1.2; /* Espaciado entre líneas */
  text-transform: uppercase; /* Transformamos a mayúsculas */
  letter-spacing: 2px; /* Espaciado entre letras */
}

/* Estilo para h2 */
#landing-page h2 {
  font-size: 2rem; /* Tamaño ligeramente más pequeño */
  font-weight: 600; /* Negrita pero menos marcada */
  color: #555; /* Un color ligeramente más claro */
  text-align: center; /* Centramos el texto */
  margin-bottom: 0.8rem; /* Espaciado inferior más pequeño */
  line-height: 1.4; /* Espaciado entre líneas más amplio */
  text-transform: capitalize; /* La primera letra en mayúsculas */
  letter-spacing: 1px; /* Espaciado sutil entre letras */
  border-bottom: 2px solid #47b2e4; /* Línea decorativa debajo */
  display: inline-block; /* Línea ajustada al contenido */
  padding-bottom: 0.3rem; /* Espaciado entre texto y la línea */
}


#landing-page  .section-header {
  font-size: 1.5rem; /* Tamaño adecuado para h3 */
  font-weight: 600; /* Negrita para destacar */
  color: #333; /* Color oscuro para buena legibilidad */
  text-align: center; /* Centrado para el encabezado */
  margin-bottom: 2rem; /* Espacio debajo del encabezado */
  display: flex; /* Usar flexbox para alinear contenido */
  align-items: center; /* Centrar ícono verticalmente */
  justify-content: center; /* Centrar ícono horizontalmente */
}

#landing-page .section-header i {
  color: #47b2e4; /* Color del ícono */
  font-size: 1.75rem; /* Tamaño del ícono */
}

#landing-page footer {
  text-align: center;
  background-color: #f8f9fa;
  color: #6c757d;
  font-size: 14px;
  border-top: 1px solid #dee2e6;
  padding: 15px 0;
}

#landing-page .qr-landing-image:hover{
  transition: transform 0.3s ease-in-out;
}

#landing-page .qr-landing-image:hover {
  transform: scale(1.4);
}

#landing-page .qr-landing-image {
  margin: 0 5px; /* Reduce horizontal margin between images */
}

#landing-page .image-section .row {
  margin-left: -5px; /* Remove extra left space from row */
  margin-right: -5px; /* Remove extra right space from row */
}

/* Establecer un ancho máximo personalizado para el contenido */
#landing-page .main-content {
  max-width: 1320px;
  margin: 0 auto; /* Centrado */
}